import React, { Component } from 'react'

class UsefulLinks extends Component {
  render() {
    return (
        <div class="section">
        <h5>Useful links</h5>
        <p><a href="https://github.com/bkklee">My GitHub</a></p>
        <p><a href="https://github.com/doggy8088/Learn-Git-in-30-days">Learn Git</a></p>
        <p><a href="https://github.com/Asabeneh/30-Days-Of-JavaScript">Learn Javascript</a></p>
        <p><a href="https://www.liaoxuefeng.com/wiki/1016959663602400">Learn Python</a></p>
        <p><a href="https://linux.vbird.org/linux_basic/centos7/">Learn computer organization and Linux</a></p>
        <p><a href="https://vuepress.mirror.docker-practice.com/">Learn Docker</a></p>
        <p><a href="https://tso-liang-wu.gitbook.io/learn-ansible-and-jenkins-in-30-days/jenkins/jenkins">Learn Jenkins</a></p>
        </div>
    )
  }
}

export default UsefulLinks