import React, { Component } from 'react'

class BeautifulTable extends Component {

    componentDidMount(){
        window.$('.tabs').tabs()
    }

    render() {

        let content = this.props.description.map((item) => <tr><td>{item["name"]}</td><td dangerouslySetInnerHTML={{ __html: item["description"] }}></td></tr>)

        return (
            <div id={this.props.id} class="col s12">
            <table class="highlight">
                <colgroup>
                    <col width="30%"></col>
                    <col width="70%"></col>
                </colgroup>
                <thead>
                    <tr>
                        <th>Git command</th>
                        <th>Explain</th>
                    </tr>
                </thead>
                <tbody class={this.props.id}>{content}</tbody>
            </table>
            </div>
        )
    }
}

export default BeautifulTable