import React, { Component } from 'react'
import './App.css';
import Sidenav from './Sidenav';
import Navbar from './Navbar';
import MainArea from './MainArea';

export class App extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      showing: "main"
    }
  }

  clickedPublications = () => {
    this.setState({
      showing: "publications"
    })
  }

  clickedHome = () => {
    this.setState({
      showing: "main"
    })
  }

  clickedCode = () => {
    this.setState({
      showing: "code"
    })
  }

  render() {
    return (
      <div className="App">
        <Sidenav clickedPublications={this.clickedPublications} clickedHome={this.clickedHome} clickedCode={this.clickedCode}/>
        <Navbar clickedPublications={this.clickedPublications} clickedHome={this.clickedHome} clickedCode={this.clickedCode}/>
        <MainArea showing={this.state.showing}/>
      </div>
    );
  }
}

export default App