import React, { Component } from 'react'
import Publications from './Publications';
import UsefulCode from './UsefulCode';
import './MainArea.css'
import Home from './Home';

class MainArea extends Component {
  render() {
    if(this.props.showing == "publications"){
      return <main><Publications/></main>
    }else if(this.props.showing == "code"){
      return <main><UsefulCode/></main>
    }else{
      return <main><Home/></main>
    }
  }
}

export default MainArea