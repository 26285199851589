import axios from 'axios'
import React, { Component } from 'react'
import BeautifulTable from './BeautifulTable'

class UsefulJS extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       category: [],
       description: []
    }
  }

  componentDidMount(){
    axios.get('json/learnJS.json').then(response => {
      this.setState({
        category: Object.keys(response.data),
        description: response.data
      })
    })
  }

  render() {

    const tableList = this.state.category.map(cat => <BeautifulTable id={cat} description={this.state.description[cat]}/>)

    return (
        <div class="section">
        <h5>Useful Javascript commands</h5>

        <div class="row learnJS">
            <div class="col s12">
            <ul class="tabs">
                <li class="tab col"><a href="#math">Math</a></li>
                <li class="tab col"><a href="#arrayMethod">Array</a></li>
                <li class="tab col"><a href="#stringMethod">String</a></li>
                <li class="tab col"><a href="#objectMethod">Object</a></li>
                <li class="tab col"><a href="#htmlElement">HTML</a></li>
                <li class="tab col"><a href="#consoleMethod">Console</a></li>
                <li class="tab col"><a href="#setMethod">Set</a></li>
                <li class="tab col"><a href="#regularExpression">Regular Expression</a></li>
                <li class="tab col"><a href="#dateMethod">Date</a></li>
                <li class="tab col"><a href="#classMethod">Class</a></li>
                <li class="tab col"><a href="#errorHandling">Error Handling</a></li>
                <li class="tab col"><a href="#localStorage">Local Storage</a></li>
                <li class="tab col"><a href="#promise">Promise</a></li>
                <li class="tab col"><a href="#miscJS">Misc</a></li>
            </ul>
            </div>

            {tableList}

        </div>
        </div>
    )
  }
}

export default UsefulJS