import axios from 'axios'
import React, { Component } from 'react'
import BeautifulTable from './BeautifulTable'

class UsefulGit extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       category: [],
       description: []
    }
  }

  componentDidMount() {
    axios.get('json/github.json').then((response)=>{
      this.setState({
        category: Object.keys(response.data),
        description: response.data
      })
    })
  }

  render() {

    const tableList = this.state.category.map(cat => <BeautifulTable id={cat} description={this.state.description[cat]}/>)

    return (
        <div class="section">
          <h5>Useful Git commands</h5>

          <div class="row github">
              <div class="col s12">
              <ul class="tabs">
                  <li class="tab col"><a href="#initial">Initialization</a></li>
                  <li class="tab col"><a href="#remoRepo">Remote repository</a></li>
                  <li class="tab col"><a href="#branch">Branch</a></li>
                  <li class="tab col"><a href="#indMan">Index management</a></li>
                  <li class="tab col"><a href="#stash">Stash</a></li>
                  <li class="tab col"><a href="#refTag">Ref/Tag</a></li>
                  <li class="tab col"><a href="#misc">Misc</a></li>
              </ul>
              </div>

              {tableList}

          </div>
        </div>
    )
  }
}

export default UsefulGit