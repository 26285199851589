import React, { Component } from 'react'

class Sidenav extends Component {

  componentDidMount(){
    window.$('.sidenav').sidenav();
  }

  render() {
    return (
        <ul class="sidenav sidenav-fixed" id="slide-out" style={{top:"65px"}}>    
            <li><a class="sidenav-close home" onClick={this.props.clickedHome}><i class="material-icons left">home</i>Home</a></li>
            <li><a class="sidenav-close publi" onClick={this.props.clickedPublications}><i class="material-icons left">description</i>Publications</a></li>
            <li><a class="sidenav-close code" onClick={this.props.clickedCode}><i class="material-icons left">folder</i>Useful code</a></li>
        </ul>
    )
  }
}

export default Sidenav