import React, { Component } from 'react'

class Navbar extends Component {
  render() {
    return (
        <header>
            <div class="navbar-fixed">
                <nav>
                    <div class="nav-wrapper green darken-2">
                        <a href="#" class="brand-logo" style={{paddingLeft:"20px"}}>K. K. Lee</a>
                        <a href="#" data-target="slide-out" class="sidenav-trigger"><i class="material-icons">menu</i></a>
                        <ul class="right hide-on-med-and-down" style={{paddingRight:"300px"}}>
                            <li><a class="home" onClick={this.props.clickedHome}><i class="material-icons left">home</i>Home</a></li>
                            <li><a class="publi" onClick={this.props.clickedPublications}><i class="material-icons left">description</i>Publications</a></li>
                            <li><a class="code" onClick={this.props.clickedCode}><i class="material-icons left">folder</i>Useful code</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
  }
}

export default Navbar