import React, { Component } from 'react'
import axios from 'axios'

class UsefulCode extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       fileData: [],
       description: []
    }
  }

  componentDidMount() {

    axios.post('cpp').then((filenames) => {
      const filePromises = filenames.data.map((filename) => {
        const filenameWithoutExtension = filename.replace('.cpp', '');
        return Promise.all([
          axios.get('cpp/' + filenameWithoutExtension + '.cpp'),
          axios.get('cpp/description/' + filenameWithoutExtension + '.txt'),
        ]);
      });

      Promise.all(filePromises)
        .then((results) => {
          const newFileData = results.map(([fileResponse]) => fileResponse.data);
          const newDescription = results.map(([, descriptionResponse]) => descriptionResponse.data);

          this.setState({
            fileData: newFileData,
            description: newDescription,
          });
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          // Handle errors appropriately
        });
    });
  }

  componentDidUpdate() {
    window.hljs.highlightAll();
  }

  render() {

    const codeList = this.state.fileData.map((file, index) => {
      return (
        <>
        <div><p>{this.state.description[index]}</p></div>
        <div>
          <article class="markdown-body">
            <pre><code id="playground">{file}</code></pre>
          </article>
        </div>
        <p><div className="divider"></div></p>
        </>
      )
    })

    return (
        <div id="thecodes" style={{padding:"0px 20px"}}>
          {codeList}
        </div>
    )
  }
}

export default UsefulCode