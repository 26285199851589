import React, { Component } from 'react'
import Weather from './Weather'
import UsefulLinks from './UsefulLinks'
import UsefulGit from './UsefulGit'
import UsefulJS from './UsefulJS'

class Home extends Component {
  render() {
    return (
        <div style={{padding: "0px 20px"}}>
            <p>Welcome to my homepage.</p>
            <div class="divider"></div>
            <Weather/>
            <div class="divider"></div>
            <UsefulLinks/>
            <div class="divider"></div>
            <UsefulGit/>
            <div class="divider"></div>
            <UsefulJS/>
        </div>
    )
  }
}

export default Home