import axios from 'axios'
import React, { Component } from 'react'

class Weather extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       weatherInfo: "",
       imgInfo: "",
       address: ""
    }
  }

  componentDidMount() {
    //Weather init
    axios.get('testing/80/105/CUHK').then(response => {
      this.setState({
        weatherInfo: response.data
      })
    })

    this.selectTime({target: {value: 0}})

    //Noti
    if (!('Notification' in window)) {
      console.log('This browser does not support notification');
    }else{
        window.Notification.requestPermission();
        setInterval(()=>{
          axios.get('testing/80/105/CUHK').then(response => {
            this.setState({
              weatherInfo: response.data
            })
            new window.Notification("Updated weather!")
          })
        }, 1000*60*6)
    }

  }

  selectTime = (event) => {
    axios.get('testImg/'+event.target.value).then(response => {
      this.setState({
        imgInfo: response.data
      })
    })
  }

  submitAddress = () => {
    axios.get('addressToPixel/'+this.state.address).then(response => {
      axios.get('testing/'+response.data.y+'/'+response.data.x+'/'+encodeURIComponent(response.data.name)).then(res => {
        this.setState({
          weatherInfo: res.data
        })
      })
    })
  }

  handleAddressChange = (event) => {
    this.setState({
      address: event.target.value
    })
  }

  render() {
    return (
        <div class="section">
          <p>Prediction Image:</p>
          <img src={`data:image/png;base64,${this.state.imgInfo}`} alt="Weather Prediction" />

          <div class="input-field col s12">
          <select class="browser-default" id="selectImg" onChange={this.selectTime}>
              <option value="0" selected>0-6 minutes</option>
              <option value="1">6-12 minutes</option>
              <option value="2">12-18 minutes</option>
              <option value="3">18-24 minutes</option>
              <option value="4">24-30 minutes</option>
              <option value="5">30-36 minutes</option>
              <option value="6">36-42 minutes</option>
              <option value="7">42-48 minutes</option>
              <option value="8">48-54 minutes</option>
              <option value="9">54-60 minutes</option>
          </select>
          </div>

          <p dangerouslySetInnerHTML={{__html: this.state.weatherInfo}}>
          </p>
          
          <div class="input-field col s6">
              <input id="address" type="text" onChange={this.handleAddressChange}/>
              <label class="active" for="first_name2">Address</label>
              <a class="waves-effect waves-light submitAddress btn-small green darken-2" onClick={this.submitAddress}>Submit</a>
          </div>
        </div>
    )
  }
}

export default Weather